<template>
	<v-dialog
		:value="value" 
		@input="$emit('input')"
		max-width="574"
		persistent
		content-class="sound-error-dialog-container">
		<div class="dialog-wrapper sound-error-dialog">
			<div class="dialog-header">
				<h2>Oops!</h2>
			</div>
			<div class="dialog-content">
				<p>Having trouble playing this track.<br>
				{{ retryStatus }}</p>
			</div>
			<div class="dialog-actions">
				<button 
					type="button" 
					class="tetriatary_btn" 
					@click="$emit('input', false)" 
					v-ripple
				>
					Close
				</button>
				<button 
					type="button" 
					class="primary_btn" 
					@click="skipTrack" 
					v-ripple
				>
					Skip Now
				</button>
			</div>
		</div>
	</v-dialog>
</template>

<script>
export default {
	name: "soundErrorDialog",
	props: {
		value: Boolean,
		retryStatus: {
			type: String,
			default: ''
		}
	},
	methods: {
		skipTrack() {
			this.$emit('input', false);
			this.$store.dispatch('SKIP_TRACK', 'next');
		}
	}
}
</script>

<style lang="scss">
@import "../../assets/styles/mixins/dialog-mixins";

.sound-error-dialog {
	@include dialog((
		has-header: true,
		has-content: true,
		has-actions: true,
		single-border: true,
		background: #282C47,
		border-color: #353B49
	));

	.dialog-content {
		color: rgba(255, 255, 255, 0.9);
		p {
			margin: 0;
			padding: 0;
		}
	}

	.dialog-actions {
		.primary_btn {
			background: linear-gradient(74.68deg, #FF0099 -12.21%, #20A8F5 112.21%);
			
			&:hover {
				background: linear-gradient(74.68deg, #FF0099 -32.21%, #20A8F5 90.21%);
			}
		}
	}
}
</style> 